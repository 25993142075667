<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-19 15:10:03
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-01 15:17:18
-->
<template>
	<div class="flex flex-wrap row-between">
		<el-select v-model="value" @change="changeValue" placeholder="全部">
			<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
		</el-select>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getFile } from '@/api/goods';

export default {
	name: 'GoodsConfigList',
	props: ['value', 'label'],

	data() {
		return {
			mValue: [],
			list: [{ name: '全部', id: -1 }],
		};
	},

	computed: {
		...mapGetters(['project']),
	},

	watch: {
		value: {
			immediate: true,
			deep: true,
			handler(newValue, oldValue) {
				this.getList();
			},
		},
	},

	methods: {
		// 获取人物分类列表
		async getList() {
			let res = await getFile();
			console.log(res);
			if (res.code == 1) {
				this.list = [{ name: '全部', id: -1 }];
				this.getTreeData(res.data);
				console.log(this.list, '----0000');
			}
		},
		changeValue() {
			console.log(this.value, '--------0000');
			this.$emit('update', this.value);
		},
		getTreeData(data) {
			// 循环遍历json数据
			for (var i = 0; i < data.length; i++) {
				data[i].children = data[i].childlist;
				this.list.push(data[i]);
				if (data[i].children.length > 0) {
					this.getTreeData(data[i].children);
				}
			}
		},
		getName(v) {
			let name = '';
			if (this.label == '人物列表' || this.label == '会员列表') {
				if (this.list.find((item) => item.id == v)) {
					name = this.list.find((item) => item.id == v).name;
				}
			}
			if (this.label == '供需分类' || this.label == '产品分类') {
				if (this.list.find((item) => item.key == v)) {
					name = this.list.find((item) => item.key == v).value;
				}
			}
			return name;
		},
		add() {
			this.$emit('edit');
		},
	},
};
</script>
<style scoped>
.type-item {
	display: flex;
	padding: 0px 10px 10px 10px;
}
</style>
